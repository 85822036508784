import React from 'react'
import styled from 'styled-components'
import {Link, navigate} from 'gatsby'

import {images} from '../styles/collections'

const Card = styled.div`
	width: 200px;
	height: 125px;
	border-radius: 8px;
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #4C9AFF;
	transition: all 300ms;
	
	img {
		width: 1.5rem;
	}

	&:hover {
		transform: translateY(-10px);
		box-shadow: 0 0 4px 0 #8777D9;
		border: 1px solid #8777D9;
	}

	h3 {
		margin-bottom: 0;
	}

	h5 {
		font-weight: 400;
		display: inline;
	}

	svg {
		width: 1.25rem;
		color: #C1C7D0;
	}
`

const Versions = styled.div`
	span {
		color: #C1C7D0;
	}

	select {
		border: none;
		color: #253858;

		&:focus {
			outline: none;
		}
	}
`
/* eslint-disable */
export default ({name, versions, collections, website}) => (
	<Link to={'/packages/' + name}>
		<Card>
			<div style={{position: 'absolute', top: 0, left: 0, margin: '.5rem 0 0 .5rem'}}>
				{collections.map((item, i) => (
					<img alt={item} key={i} src={images[item].default} />
				))}
			</div>
			{website && (<div style={{position: 'absolute', top: 0, right: 0, margin: '.25rem .5rem 0 .5rem'}}>
				<svg style={{width: '1rem'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 152v-32c0-4.4 3.6-8 8-8h208c4.4 0 8 3.6 8 8v32c0 4.4-3.6 8-8 8H136c-4.4 0-8-3.6-8-8zm8 88h208c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8H136c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8zm299.1 159.7c-4.2 13-4.2 51.6 0 64.6 7.3 1.4 12.9 7.9 12.9 15.7v16c0 8.8-7.2 16-16 16H80c-44.2 0-80-35.8-80-80V80C0 35.8 35.8 0 80 0h352c8.8 0 16 7.2 16 16v368c0 7.8-5.5 14.2-12.9 15.7zm-41.1.3H80c-17.6 0-32 14.4-32 32 0 17.7 14.3 32 32 32h314c-2.7-17.3-2.7-46.7 0-64zm6-352H80c-17.7 0-32 14.3-32 32v278.7c9.8-4.3 20.6-6.7 32-6.7h320V48z"></path></svg>
			</div>)}
			<h3>{name}</h3>
			<Versions>
				<span>({versions.length})</span>
				<select style={{maxWidth: '5rem'}} defaultValue={'PLACEHOLDER'} onClick={(e) => e.preventDefault()} onChange={(e) => {
					navigate(`/packages/${name}/${e.target.value}`)
					}}>
					{[...versions].reverse().map((version, i) => (
						<option key={i} value={version}>v{version}</option>
					))}
					<option key={Number.MAX_SAFE_INTEGER} value="PLACEHOLDER" hidden>v{versions[versions.length - 1]}</option>
				</select>
			</Versions>
		</Card>
	</Link>
)