import React, {useState} from 'react'
import styled from 'styled-components'
import {Helmet} from 'react-helmet'

import Header from '../components/header'
import Card from '../components/card'
import {breakpoints} from '../styles/helpers'

import {images} from '../styles/collections'
import {Gradient} from '../styles/styledComps'
import data from '../../data/main.json'

const IndexPage = styled.div`
	margin: 0 auto;
	max-width: 1500px;
	padding: 0 1rem;

	@keyframes beat {
		0%, 50%, 100% { transform: scale(1, 1); }
		30%, 80% { transform: scale(0.93, 0.93); }
	}
`

const Search = styled.input`
	border: 2px solid transparent;
	border-radius: 4px;
	border: 2px solid #DFE1E6;
	margin-top: 2rem;
	width: 400px;

	${breakpoints.lg} {
		width: 100%;
	}

	&:focus {
		border: 2px solid #0065FF;
		outline: none;
	}
`

const CardBox = styled.div`
	display: grid;
	padding-top: 1rem;
	grid-gap: 32px;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr) );
	justify-items: center;
`

const Collections = styled.div`
	margin: 1rem 0;

	img {
		max-width: 4rem;
		margin-right: -0.25rem;

		&:hover {
			opacity: 1 !important;
		}
	}
`

const HeartFilled = () => (
	<svg style={{paddingBottom: '5px', width: '1.5rem', fill: '#DE350B', animation: '2s ease-in-out 0s infinite beat'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 
		12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
	</svg>
)

export default () => {
	const [collection, setCollection] = useState([])
	const [search, setSearch] = useState('')

	const favoritePackages = (typeof window !== `undefined` && window.localStorage.getItem("mpn-favorites") !== null) ? JSON.parse(window.localStorage.getItem("mpn-favorites")) : []
	return(
		<>
		<Gradient />
		<Header page='index' />
		<IndexPage>
			<Helmet title='MPN | Metrum Package Network' />

			<Search type="text" placeholder="Search Packages..." onChange={(e) => setSearch(e.target.value)} />
			<Collections>
				{Object.keys(images).map((img, i) => ( // eslint-disable-next-line
					<img key={i} alt={img} src={images[img].default} style={{opacity: collection.includes(img) ? 1 : 0.6}} 
						onClick={() => (collection.includes(img) ? setCollection(collection.filter(x => x !== img)) : setCollection([...collection, img]))} 
					/>
				))}
			</Collections>
			<div style={{height: '70vh', overflow: 'scroll'}}>
				{favoritePackages.length > 0 && (
					<>
					<h2><HeartFilled /> Packages</h2>
					<CardBox>
						{data.filter(pkg => (collection.length === 0 || pkg.collections.some(e => collection.includes(e))))
							.filter(pkg => (search === '' || pkg.name.includes(search)))
							.filter(pkg => favoritePackages.includes(pkg.name)).map((pkg, i) => (
								<Card website={pkg.website} collections={pkg.collections} key={i} name={pkg.name} versions={pkg.versions} />
						))}
					</CardBox>
					<hr style={{marginTop: 'calc(2rem - 1px)'}}/>
					</>
				)}
				<CardBox>
					{data.filter(pkg => (collection.length === 0 || pkg.collections.some(e => collection.includes(e))))
						.filter(pkg => pkg.in_latest)
						.filter(pkg => (search === '' ||
								pkg.name.toLowerCase().includes(search.toLowerCase()))
						       ).map((pkg, i) => (
							<Card website={pkg.website} collections={pkg.collections} key={i} name={pkg.name} versions={pkg.versions} />
					))}
				</CardBox>
			</div>
		</IndexPage>
		</>
	)
}
